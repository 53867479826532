<template>
  <div>
    <h4>레시피 수정</h4>

    <el-button type="primary" @click="$router.push({ name: 'RecipeList' })"
      >레시피 리스트로 돌아가기</el-button
    >

    <el-input v-model="title" />

    <QuillEditor ref="editor" :modules="modules" theme="snow" toolbar="full" />

    <el-button type="primary" @click="save">저장</el-button>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import BlotFormatter from "quill-blot-formatter";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import RecipeService from "../services/RecipeService";

export default {
  components: {
    QuillEditor,
  },
  setup: () => {
    const modules = {
      name: "blotFormatter",
      module: BlotFormatter,
      options: {},
    };
    return { modules };
  },
  data() {
    return {
      recipeId: null,
      title: "",
    };
  },
  methods: {
    async save() {
      if (!this.title) {
        this.$message.error(`레시피의 제목을 입력해주세요.`);
        return;
      }

      try {
        const content = this.$refs.editor.getHTML();
        console.log(content);

        await RecipeService.update(this.recipeId, {
          title: this.title,
          content,
        });

        this.$message.success("레시피가 업데이트되었습니다.");
      } catch (e) {
        console.log(e);
      }
    },
  },
  async mounted() {
    this.recipeId = this.$route.params.id;

    try {
      const resp = await RecipeService.get(this.recipeId);
      const recipe = resp.data;
      console.log("recipe: ", recipe);

      this.title = recipe.title;
      this.$refs.editor.setHTML(recipe.content);
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style>
</style>