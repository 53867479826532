import http from '../http-common';

class RecipeService {
  create(data) {
    return http.post('/recipe/create', data);
  }

  getAll() {
    return http.get('/recipe/getAll');
  }

  get(id) {
    return http.get(`/recipe/get/${id}`);
  }

  update(id, data) {
    return http.put(`/recipe/update/${id}`, data);
  }
}

export default new RecipeService();
